<template>
	<div style="display:block" class="layer">
		<div class="layer__dim"></div>
		<div class="layer__full">
			<div class="layer__fullbox">
				<div class="layer__fullheader">
					<nav class="nav">
						<div class="nav__wrap">
							<a class="nav__btn" @click="goBack()">
								<i class="icon icon-close"></i>
							</a>
							<p class="nav__item">
								<span class="nav__text">
									{{t('10521')}}
								</span>
							</p>
							<a href="#" class="nav__btn">
							</a>
						</div>
					</nav>
				</div>
				<div class="layer__fullbody">
					<section class="galleries">
						<div class="box">
							<article class="galleries__item">
								<h2 class="galleries__title">{{t('10522')}}</h2>
								<div class="galleries__wrap" v-if="hotelImages.length > 0">
									<div class="galleries__slider">
										<div class="galleries__imagebox noneimage">
											<div class="imagebg" :style="{'background-image': 'url(\'' + hotelImages[0].IMG_URL + '\')'}">
											</div>
										</div>
									</div>
								</div>
							</article>
							<article class="galleries__item" v-for="(room, index) in Object.keys(roomImages)" :key="room">
								<h2 class="galleries__title" v-if="index === 0">객실</h2>
								<h3 class="galleries__subtitle">{{room}}</h3>
								<div class="galleries__wrap">
									<div class="galleries__slider">
										<swiper @slideChange="setSlideCurrentIndex($event, room)" data-currentnum=".gallery__currentnum--2" :slides-per-view="1.2" :space-between="8">
											<swiper-slide v-for="image in roomImages[room]" :key="image">
												<div class="galleries__imagebox noneimage">
													<div class="imagebg" :style="{'background-image': 'url(\'' + image + '\')'}">
													</div>
												</div>
											</swiper-slide>
										</swiper>
									</div>
									<span class="gallery__badge">
										<strong class="gallery__currentnum gallery__currentnum--2">{{(nowSlideIndex[room] ?? 0) + 1}}</strong>
										<i class="gallery__dot"></i>
										<small>{{roomImages[room].length}}</small>
									</span>
								</div>
							</article>
						</div>
					</section>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { computed, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n' 
import i18n from "@/i18n"; 
export default {
	setup() {
		const store = useStore();
		const router = useRouter();
		const nowSlideIndex = ref({});
		const { t }= useI18n() 
		const setSlideCurrentIndex = (swiper, roomId) => {
			const index = swiper.realIndex;
			nowSlideIndex.value[roomId] = index;
		}
		const route = useRoute();
		const hotelNo = route.params.id;

		const goBack = () => {
			router.go(-1);
		}
	
		onMounted(() => {
			store.dispatch("mrm02/fetchMrm0203", { hotelNo });
			store.dispatch("mrm02/fetchRoomsImages", { hotelNo });
		});
		
		const roomImages = computed(() => {
			const state = store.state.mrm02.roomsImages[hotelNo];
			if(state === undefined) return {};
			const imagesByRoom = {};
			state.forEach(image => {
				if(imagesByRoom[image.IMG_DESC] === undefined) {
					imagesByRoom[image.IMG_DESC] = [];
				}
				imagesByRoom[image.IMG_DESC].push(image.IMG_URL);
			});
			return imagesByRoom;
		})
		return {
			setSlideCurrentIndex,
			goBack,
			t,  //번역필수 모듈
		  i18n, 
			hotelImages: computed(() => (store.state.mrm02.mrm0203[hotelNo] || [])),
			roomImages,
			nowSlideIndex
		}
	},
}
</script>